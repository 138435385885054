import React, {Component} from "react";
import { Link } from "gatsby";

import $ from 'jquery';
import Cookies from 'js-cookie';

import './index.scss';

import CallMade from '@material-ui/icons/CallMade';

import FormField from '../../utilities/FormField';

import iconLinked from '../../../images/icon-linkedin.svg';
import iconYoutube from '../../../images/icon-youtube.svg';
import iconFacebook from '../../../images/icon-facebook.svg';
import iconTwitter from '../../../images/icon-twitter.svg';
import iconInstagram from '../../../images/icon-instagram.svg';
import iconMap from '../../../images/icon-map.svg';
import iconPhone from '../../../images/icon-phone.svg';

/* const recaptchaRef = React.createRef();

function onChange(value) {
    console.log("Captcha value:", value);
} */

class FooterForm extends Component {
    constructor(props){
      super(props);
      this.state = {
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          contactMessage: ''
      };

      this.submit = this.submit.bind(this);
      this.nameChange = this.nameChange.bind(this);
      this.emailChange = this.emailChange.bind(this);
      this.phoneChange = this.phoneChange.bind(this);
      this.msgChange = this.msgChange.bind(this);
  }

  submit(e) {
      e.preventDefault();
      let formObject = $('#footerForm');
      let postDt = new Date();
      let loadDt = new Date();
      //let _sid = 'qq3eu25egawyabolsjpyzcr5';
      let _sid = Cookies.get('ASP.NET_SessionId');
      if (formObject.find('input[name="js"]').length === 0) {
          let str = '<input type="hidden" name="js" value="1" />';
          formObject.append(str);
      }
      if (formObject.find('input[name="ttp"]').length === 0) {
          let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
          formObject.append(str);
      }
      if (formObject.find('input[name="sid"]').length === 0) {
          let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
          formObject.append(str);
      } else {
          formObject.find('input[name="sid"]').val(_sid);
      }

      let post_data = formObject.serialize();

      console.log(post_data);

      this.setState({
          contactName: '',
          contactEmail: '',
          contactPhone: '',
          contactMessage: ''
      });

      $.ajax({
          url: "https://metrix.meritmile.com/email.aspx?ajax=1",
          type: 'POST',
          data: post_data,
          cache: false,
          dataType: 'json',
          success: function(data) {
              // Success..
              let r = data[0];

              formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
          },
          // Fail..
          error: function(result) {
            let r = result[0];

              formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
              formObject.find('.submit').attr('disabled', false).removeClass('disabled');
          }
      });
  }

  nameChange(e){
      this.setState({contactName: e.target.value})
  }

  emailChange(e){
      this.setState({contactEmail: e.target.value})
  }

  phoneChange(e){
      this.setState({contactPhone: e.target.value})
  }

  msgChange(e){
      this.setState({contactMessage: e.target.value})
  }

  render() {
    return  (
      <form id="footerForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
            <FormField fieldID={"name"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Name"}/>
            <FormField fieldID={"email"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
            <FormField fieldID={"telephone"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
            <FormField fieldID={"message"}  valChange={this.msgChange} val={this.state.contactMessage} type={"text"}  name={"message"} label={"Message"}/>
            <button type="submit" className="btn btn-white">Get in Touch</button>
            <div className="form-group">
                <input type="hidden" name="referrer" value="thisurl" />
                <input type="hidden" name="auth" value="788aa7eb-8ceb-498d-b0b4-002c85f56fb4" />
                <input type="text" className="d-none hu" id="human-check" name="human-check" />
                <div className="feedback"></div>
                <div className="fix"></div>
            </div>
      </form>
    );
  }
}

class Footer extends Component {
  render() {
    if(this.props) {
      return  (
        <footer
          style={{
            backgroundColor: 'white'
          }}
          className="footer" 
          id="footer"
        >
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-md-6">
                <div className="map-wrapper">
                  <h2>We'd love to hear from you!</h2>
                  <h3>Headquarters</h3>
                  <div className="row">
                    <div className="col-md-10">
                      <h4>Boca Raton, FL</h4>
                      <div className="bar">&nbsp;</div>
                      <div className="address">
                        <div className="icon"><a href="https://www.google.com/maps/place/Merit+Mile/@26.351829,-80.0881308,17z/data=!3m1!4b1!4m5!3m4!1s0x88d8e1f0ec0765a9:0x496e6300fab8aea0!8m2!3d26.351829!4d-80.0859421" target="_blank" rel="noopener noreferrer"><img src={iconMap} alt=""/></a></div>
                        <div className="copy">
                            <a href="https://www.google.com/maps/place/Merit+Mile/@26.351829,-80.0881308,17z/data=!3m1!4b1!4m5!3m4!1s0x88d8e1f0ec0765a9:0x496e6300fab8aea0!8m2!3d26.351829!4d-80.0859421" target="_blank" rel="noopener noreferrer">131 NE 1st Avenue, Suite 100<br />Boca Raton, FL 33432 USA</a>
                        </div>
                    </div>
                    <div className="phones">
                        <div className="icon"><a href="tel:5613628888" target="_blank" rel="noopener noreferrer"><img src={iconPhone} alt="561-362-8888"/></a></div>
                        <div className="copy">(561) 362-8888</div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-wrapper">
                  <CallMade className={"expand_arrow"} />
                  <div className="inner-form">
                      <h2>Connect</h2>
                      <FooterForm />
                    <div className="social-icons">
                      <div className="icon"><a href="https://mm-stats.com/ednpe" target="_blank" rel="noopener noreferrer"><img src={iconInstagram} alt="Instagram"/></a></div>
                      <div className="icon"><a href="https://mm-stats.com/jytor" target="_blank" rel="noopener noreferrer"><img src={iconLinked} alt="LinkedIn"/></a></div>
                      <div className="icon"><a href="https://mm-stats.com/jv4iq" target="_blank" rel="noopener noreferrer"><img src={iconYoutube} alt="YouTube"/></a></div>
                      <div className="icon"><a href="https://mm-stats.com/i4qef" target="_blank" rel="noopener noreferrer"><img src={iconFacebook} alt="Facebook"/></a></div>
                      <div className="icon"><a href="https://mm-stats.com/9popn" target="_blank" rel="noopener noreferrer"><img src={iconTwitter} alt="Twitter"/></a></div>
                    </div>
                    <div className={"footer-links"}><Link to='/metrix/'>METRIX</Link> | <Link to='/careers/'>CAREERS</Link> | <Link to='/legal/'>LEGAL</Link> | <a href="/cookies/">COOKIES</a> | <Link to='/site-map/'>SITE MAP</Link></div>
                    <div className={"footer-legal"}>&copy; {new Date().getFullYear()} Merit Mile LLC. All rights reserved.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )
    }
  }
}

export default Footer